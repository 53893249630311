<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <div
      style="
        width: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
      "
    >
      <div class="filters-actions-container">
        <form @submit.prevent="searchBancale" class="filters">
          <v-expansion-panels
            style="width: 300px !important; z-index: 3"
            v-model="this.panel"
          >
            <v-expansion-panel
              title="ALTRI FILTRI"
              style="
                width: 300px !important;
                position: absolute;
                border-radius: 5px !important;
                border: thin solid #d0d0d0;
                background-color: white;
              "
              density="comfortable"
              value="1"
            >
              <v-expansion-panel-text style="width: 300px !important">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    row-gap: 5px;
                    width: 100%;
                  "
                >
                  <v-switch
                    label="AFFIDATO"
                    v-model="this.spedito"
                    color="#2196f3"
                    hide-details
                    style="
                      width: 250px !important;
                      box-shadow: none !important;
                      border-radius: none !important;
                    "
                  ></v-switch>
                  <v-dialog width="375px">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        width="250"
                        variant="outlined"
                        text="DATA SPEDIZIONE DA"
                        height="48px"
                      ></v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                      <v-card title="SELEZIONA DATA SPEDIZIONE DA">
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <v-date-picker
                            :max="this.dataSpedizioneA"
                            style="border: 1px solid #d0d0d0 !important"
                            v-model="this.dataSpedizioneDa"
                          ></v-date-picker>
                        </div>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text="CANCELLA"
                            @click="this.dataSpedizioneDa = null"
                            class="bg-red"
                            variant="outlined"
                          ></v-btn>
                          <v-btn
                            text="INVIA"
                            @click="
                              () => {
                                isActive.value = false;
                              }
                            "
                            class="bg-green"
                            variant="outlined"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                  <small>{{ this.formatDate(dataSpedizioneDa) }}</small>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    row-gap: 5px;
                    width: 100%;
                  "
                >
                  <v-dialog width="375px">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        width="250"
                        variant="outlined"
                        text="DATA SPEDIZIONE A"
                        height="48px"
                      ></v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                      <v-card title="SELEZIONA DATA SPEDIZIONE A">
                        <div
                          style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <v-date-picker
                            :min="this.dataSpedizioneDa"
                            style="border: 1px solid #d0d0d0 !important"
                            v-model="this.dataSpedizioneA"
                          ></v-date-picker>
                        </div>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text="CANCELLA"
                            @click="
                              () => {
                                dataSpedizioneA = null;
                              }
                            "
                            class="bg-red"
                            variant="outlined"
                          ></v-btn>
                          <v-btn
                            text="INVIA"
                            @click="
                              () => {
                                isActive.value = false;
                              }
                            "
                            class="bg-green"
                            variant="outlined"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                  <small>{{ this.formatDate(dataSpedizioneA) }}</small>
                </div>
                <v-select
                  :items="['APERTO', 'CHIUSO']"
                  density="comfortable"
                  label="STATO"
                  variant="outlined"
                  v-model="this.stato"
                  hide-details
                  style="width: 250px; flex: none"
                  :clearable="true"
                ></v-select>
                <v-select
                  :items="['FEDEX', 'DHL']"
                  density="comfortable"
                  label="CORRIERE"
                  variant="outlined"
                  v-model="this.corriere"
                  hide-details
                  style="width: 250px; flex: none; margin-top: 10px"
                  :clearable="true"
                ></v-select>
                <v-text-field
                  density="comfortable"
                  variant="outlined"
                  label="CERCA UTENTE"
                  append-inner-icon="mdi-magnify"
                  hide-details
                  style="width: 100% !important; margin-top: 10px"
                  v-model="searchingUtente.text"
                  @input=""
                ></v-text-field>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-text-field
            density="comfortable"
            variant="outlined"
            label="CERCA BANCALE"
            append-inner-icon="mdi-magnify"
            hide-details
            style="width: 200px !important; flex: none !important"
            v-model="searchingBancale.text"
            :clearable="true"
          ></v-text-field>
          <v-text-field
            density="comfortable"
            variant="outlined"
            label="CERCA LDV"
            append-inner-icon="mdi-magnify"
            hide-details
            style="width: 200px !important; flex: none !important"
            v-model="searchingLdV.text"
            :clearable="true"
          ></v-text-field>
          <v-btn
            type="submit"
            icon="mdi-magnify"
            class="bg-blue"
            variant="outlined"
          />
          <v-btn
            variant="outlined"
            height="48"
            width="120"
            class="bg-red"
            @click="resetFiltri"
            >RESET FILTRI</v-btn
          >
        </form>
      </div>
    </div>
    <div style="width: 95%">
      <p
        style="color: red; width: 100%; text-align: center"
        v-if="this.spedito"
      >
        <strong
          >ATTENZIONE! Stai visualizzando soltanto i bancali affidati al
          corriere (spediti)</strong
        >
      </p>
      <v-data-table-server
        v-model:items-per-page-options="options"
        :fixed-header="true"
        show-select
        v-model="this.selected"
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sortBy"
        :loading="loading"
        :headers="headers"
        :items="this.bancaliServer"
        :items-length="totalItems"
        return-object
        item-key="codicePallet"
        item-name="codicePallet"
        @update:options="loadItems"
        style="
          width: 100%;
          border: 1px solid #d0d0d0;
          border-radius: 5px;
          max-height: 475px;
        "
      >
        <template v-slot:item.aperto="{ item }">
          <p v-if="item.aperto">APERTO</p>
          <p v-else>CHIUSO</p>
        </template>
        <template v-slot:item.spedito="{ item }">
          <p v-if="item.spedito">SI</p>
          <p v-else>NO</p>
        </template>
        <template v-slot:item.corriere="{ item }">
          <p>{{ item.corriere.toUpperCase() }}</p>
        </template>
        <template v-slot:item.dataAggiornamento="{ item }">
          <p
            v-if="
              item.dataAggiornamento === null ||
              item.dataAggiornamento === 'null'
            "
          >
            NESSUNO
          </p>
          <p v-else>{{ this.formattaDataOra(item.dataAggiornamento) }}</p>
        </template>
        <template v-slot:item.dataSpedizione="{ item }">
          <p
            v-if="
              item.dataSpedizione === null || item.dataSpedizione === 'null'
            "
          ></p>
          <p v-else>{{ this.formattaDataOra(item.dataSpedizione) }}</p>
        </template>
        <template v-slot:item.emailAggiornatore="{ item }">
          <p
            v-if="
              item.emailAggiornatore === null ||
              item.emailAggiornatore === 'null'
            "
          >
            NESSUNO
          </p>
          <p v-else>{{ item.emailAggiornatore }}</p>
        </template>
        <template v-slot:item.id="{ item }">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 10px;
            "
          >
            <v-icon
              icon="mdi-information-outline"
              @click="openDetails(item.codicePallet)"
              color="blue"
              size="x-large"
              v-tooltip="'Apri dettagli bancale'"
            ></v-icon>

            <v-tooltip
              location="top"
              text="Affida bancale"
              v-if="!item.spedito"
            >
              <template v-slot:activator="{ props }">
                <Icon
                  v-bind="props"
                  icon="mdi:local-shipping"
                  width="28"
                  height="28"
                  style="color: green; cursor: pointer"
                  @click="shipBancale(item.codicePallet, true)"
                />
              </template>
            </v-tooltip>
            <v-icon
              v-if="item.spedito"
              icon="mdi-file-edit"
              @click="modificaBancale(item)"
              color="black"
              size="x-large"
              v-tooltip="'Modifica data affidamento bancale'"
            ></v-icon>
            <v-tooltip
              location="top"
              text="Aggiungi una AWB al pallet"
              v-if="!item.spedito"
            >
              <template v-slot:activator="{ props }">
                <Icon
                  v-bind="props"
                  icon="mdi:box-variant-add"
                  width="28"
                  height="28"
                  style="color: #2196f3; cursor: pointer"
                  @click="
                    () => {
                      this.idBancaleSelected = item.codicePallet;
                      this.dialogAdd = true;
                    }
                  "
                />
              </template>
            </v-tooltip>
          </div>
        </template>
      </v-data-table-server>

      <div
        style="
          display: flex;
          align-items: center;
          justify-content: start;
          column-gap: 10px;
          margin-bottom: 20px;
        "
      >
        <v-btn
          variant="outlined"
          height="48px"
          style="margin-top: 20px; background-color: #1d6f42; color: white"
          @click="exportExcel"
          >ESPORTA EXCEL</v-btn
        >
        <v-btn
          variant="outlined"
          height="48px"
          style="margin-top: 20px"
          @click="showStampaFogli"
          >CHIUDI e STAMPA BANCALI</v-btn
        >
        <v-btn
          variant="outlined"
          height="48px"
          style="margin-top: 20px"
          @click="unisciBancali"
          >UNISCI BANCALI</v-btn
        >
        <v-btn
          variant="outlined"
          height="48px"
          style="margin-top: 20px"
          @click="doMultipleShipBancale"
          >AFFIDA BANCALI</v-btn
        >
      </div>
    </div>
  </div>
  <v-dialog width="fit-content" v-model="this.dialogStampa">
    <v-card title="CHIUDI e STAMPA BANCALI">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 20px;
        "
      >
        <v-select
          :item-props="stampantiProps"
          :items="stampanti"
          label="STAMPANTE"
          v-model="this.stampanteSelected"
          density="comfortable"
          variant="outlined"
          hide-details
          style="width: 200px !important; flex: none !important"
        ></v-select>
        <v-text-field
          density="comfortable"
          variant="outlined"
          label="CODICE POSTAZIONE"
          hide-details
          style="width: 200px !important; flex: none !important"
          v-model="codicePostazione.text"
        ></v-text-field>
        <v-select
          :items="['ORDINE', 'RESO']"
          density="comfortable"
          label="TIPO BANCALE"
          variant="outlined"
          v-model="this.tipoBancale"
          hide-details
          style="width: 210px"
        ></v-select>
        <small style="color: red">{{ this.stampaError }}</small>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text="ANNULLA"
          @click="this.dialogStampa = false"
          class="bg-red"
          variant="outlined"
        ></v-btn>
        <v-btn
          text="INVIA"
          @click="stampaFogli"
          class="bg-green"
          variant="outlined"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog width="fit-content" v-model="this.dialogAdd">
    <v-card>
      <v-card-title>AGGIUNGI AWB</v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            style="width: 300px"
            label="CODICE AWB"
            required
            variant="outlined"
            v-model="this.codiceAwb"
            hide-details
          ></v-text-field>
        </v-container>
        <small style="color: red">{{ this.errorMsg }}</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" variant="outlined" @click="this.dialogAdd = false">
          CHIUDI
        </v-btn>
        <v-btn
          @click="aggiungiCollo"
          color="black"
          variant="outlined"
          class="bg-green"
          text="INVIA"
        >
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from "vuex";
import { Icon } from "@iconify/vue";
const axios = require("axios");
import Swal from "sweetalert2";
const { DateTime } = require("luxon");
export default {
  name: "BancaliUscitaTable",

  setup() {
    const store = useStore();
    return {
      store,
      dateTime: DateTime,
    };
  },

  components: {
    Icon,
  },

  data() {
    return {
      searchingUtenteText: "",
      searchingUtente: {
        text: "",
      },
      panel: null,
      itemsPerPage: 10,
      selectedPalletId: null,
      idBancaleSelected: null,
      page: 1,
      sortBy: [{ key: "dataAggiornamento", order: "desc" }],
      waybillCode: "",
      codiceAwb: "",
      headers: [
        {
          title: "CODICE BANCALE",
          value: "codicePallet",
          key: "codicePallet",
          sortable: true,
        },
        {
          title: "N° PEZZI",
          value: "colliCount",
          sortable: false,
          align: "center",
        },
        {
          title: "STATO BANCALE",
          value: "aperto",
          key: "aperto",
          sortable: true,
        },
        { title: "AFFIDATO", value: "spedito", key: "spedito", sortable: true },
        {
          title: "CORRIERE",
          value: "corriere",
          key: "corriere",
          sortable: true,
        },
        {
          title: "ULTIMO AGGIORNAMENTO",
          value: "dataAggiornamento",
          key: "dataAggiornamento",
          sortable: true,
        },
        {
          title: "AFFIDATO IL",
          value: "dataSpedizione",
          key: "dataSpedizione",
          sortable: true,
        },
        {
          title: "AUTORE AGGIORNAMENTO",
          value: "emailAggiornatore",
          key: "emailAggiornatore",
          sortable: false,
        },
        { title: "", value: "id", sortable: false },
      ],
      searchingPalletText: "",
      searchingLdvText: "",
      workstationCode: "",
      selectedPrinter: null,
      selectedPallets: [],
      searchingBancale: {
        text: "",
      },
      searchingLdV: {
        text: "",
      },
      codicePostazione: {
        text: "",
      },
      stampanteSelected: null,
      bancaliServer: [],
      selected: [],
      loading: true,
      courier: null,
      palletStatus: "CHIUSO",
      palletType: "ORDINE",
      printDialog: false,
      addDialog: false,
      totalPallets: 0,
      printError: "",
      printers: [],
      corriere: null,
      stato: "CHIUSO",
      tipoBancale: "ORDINE",
      dialogStampa: false,
      dialogAdd: false,
      totalItems: 0,
      stampaError: "",
      stampanti: [],
      options: [10, 20, 30, 40, 50],
      shipped: false,
      shipmentDateTo: null,
      shipmentDateFrom: null,
      spedito: false,
      dataSpedizioneA: null,
      dataSpedizioneDa: null,
      errors: [],
    };
  },

  methods: {
    async exportExcel() {
      const url = `${this.$apiBaseUrl}/export/pallet`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
        },
        responseType: "blob",
      };

      let filters = {
        stato: this.stato === "CHIUSO" ? false : this.stato === "APERTO",
        spedito:
          this.dataSpedizioneA || this.dataSpedizioneDa || this.spedito == true
            ? true
            : false,
        codicePallet:
          this.searchingBancale.text == "" ? null : this.searchingBancale.text,
        ldv: this.searchingLdV.text == "" ? null : this.searchingLdV.text,
        corriere: this.corriere == null ? null : this.corriere.toLowerCase(),
        dataSpedizioneDa:
          this.dataSpedizioneDa == null
            ? null
            : DateTime.fromJSDate(this.dataSpedizioneDa)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        dataSpedizioneA:
          this.dataSpedizioneA == null
            ? null
            : DateTime.fromJSDate(this.dataSpedizioneA)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        userMail:
          this.searchingUtente.text.trim().toLowerCase() == ""
            ? null
            : this.searchingUtente.text.trim().toLowerCase(),
      };

      let urlParams = {
        filtro: filters,
        sortBy: this.sortBy[0],
        page: this.page - 1,
        itemsPage: this.itemsPerPage,
      };

      try {
        const response = await axios.post(url, urlParams, config);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response.data);
        link.download = "bancali-uscita.csv";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento del file excel`,
          icon: "error",
        });
      }
    },
    stampantiProps(item) {
      return {
        title: item.name,
      };
    },
    searchBancale() {
      this.selected = [];
      this.loadItems({
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        sortBy: this.sortBy,
      });
    },
    resetFiltri() {
      this.searchingBancale.text = "";
      this.searchingLdV.text = "";
      this.corriere = null;
      this.stato = "CHIUSO";
      this.spedito = false;
      this.dataSpedizioneA = null;
      this.dataSpedizioneDa = null;
      this.searchingUtente.text = "";
      this.searchBancale();
    },
    async stampaChiudiBancale(pallet) {
      const apiUrl = `${this.$apiBaseUrl}/pallet/closePallet/${pallet.codicePallet}`;

      let datiStampa = {
        printer: this.stampanteSelected._id,
        order: this.tipoBancale == "ORDINE" ? true : false,
        postazione: this.codicePostazione.text,
      };

      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.post(apiUrl, datiStampa, config);
        console.log("Response:", response.data);
      } catch (error) {
        console.error("Error:", error.message);
        this.errors.push(pallet.codicePallet);
      }
    },
    async stampaFogli() {
      if (this.codicePostazione.text == "" || this.stampanteSelected == null) {
        this.stampaError =
          "Selezionare la stampante e inserire il codice postazione";
      } else {
        const promises = this.selected.map((pallet) =>
          this.stampaChiudiBancale(pallet)
        );
        await Promise.all(promises);
        if (this.errors.length == 0) {
          this.dialogStampa = false;
          Swal.fire({
            title: "STAMPA RIUSCITA",
            text: `I bancali sono stati chiusi e stampati con successo`,
            icon: "success",
          });
          this.errors = [];
          this.searchBancale();
        } else {
          this.dialogStampa = false;
          Swal.fire({
            title: "ERROR",
            text: `Alcuni bancali non sono stati mandati in stampa: ${this.errors}`,
            icon: "error",
          });
          this.errors = [];
        }
      }
    },
    async aggiungiCollo() {
      if (this.codiceAwb != null && this.codiceAwb.trim() != "") {
        const apiUrl = `${this.$apiBaseUrl}/pallet/addColloToPallet`;
        const bearerToken = this.$keycloak.token;
        console.log(bearerToken);

        const config = {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        };

        const awbPalletData = {
          palletId: this.idBancaleSelected,
          trackingNumber: this.codiceAwb,
        };

        try {
          const response = await axios.post(apiUrl, awbPalletData, config);
          console.log("Response:", response.data);
          this.dialogAdd = false;
          this.codiceAwb = "";
          Swal.fire({
            title: "AGGIUNTA RIUSCITA",
            text: `AWB aggiunta con successo al bancale ${this.idBancaleSelected}`,
            icon: "success",
          });
        } catch (error) {
          console.error("Error:", error.message);
          Swal.fire({
            title: "ERRORE",
            text: `C'è stato un errore nell'inserimento dell'awb nel bancale`,
            icon: "error",
          });
        }
      } else {
        this.errorMsg = "Inserire awb";
      }
    },
    async showStampaFogli() {
      if (this.selected.length != 0) {
        const apiUrl = `${this.$apiBaseUrl}/printer`;
        const bearerToken = this.$keycloak.token;
        console.log(bearerToken);

        const config = {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        };

        try {
          const response = await axios.get(apiUrl, config);
          console.log("Response:", response.data);
          this.stampanti = response.data;
          this.dialogStampa = true;
        } catch (error) {
          console.error("Error:", error.message);
          Swal.fire({
            title: "ERRORE",
            text: `C'è stato un errore nel caricamento delle stampanti`,
            icon: "error",
          });
        }
      } else {
        Swal.fire({
          title: "ATTENZIONE",
          text: `Devi selezionare dei bancali prima di mandarli in stampa`,
          icon: "warning",
        });
      }
    },
    async unisciBancali() {
      if (this.selected.length > 1) {
        const isApertoFalseForAll = this.selected.every(
          (obj) => obj.aperto === false
        );
        const firstCorriere = this.selected[0].corriere;

        const isSameCorriereForAll = this.selected.every(
          (obj) => obj.corriere === firstCorriere
        );

        const isAffidatoFalseForAll = this.selected.every(
          (obj) => obj.spedito === false
        );

        if (
          isApertoFalseForAll &&
          isSameCorriereForAll &&
          isAffidatoFalseForAll
        ) {
          console.log(
            "Tutti gli oggetti hanno 'aperto' a false, 'spedito' a false e lo stesso valore di 'corriere'."
          );

          const apiUrl = `${this.$apiBaseUrl}/pallet/unionPallet`;
          const bearerToken = this.$keycloak.token;
          console.log(bearerToken);

          const config = {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          };

          let palletsId = [];

          this.selected.forEach((pallet) => {
            palletsId.push(pallet.codicePallet);
          });

          let palletList = {
            palletList: palletsId,
          };

          try {
            const response = await axios.post(apiUrl, palletList, config);
            console.log("Response:", response.data);
            Swal.fire({
              title: "BANCALI UNITI",
              text: `I bancali sono stati uniti con successo`,
              icon: "success",
            });
            this.searchBancale();
          } catch (error) {
            console.error("Error:", error.message);
            Swal.fire({
              title: "ERRORE",
              text: `C'è stato un errore nell'unione dei bancali`,
              icon: "error",
            });
          }
        } else {
          Swal.fire({
            title: "ATTENZIONE",
            text: "Selezionare solo bancali chiusi, non spediti e dello stesso corriere",
            icon: "warning",
          });
        }
      } else {
        Swal.fire({
          title: "ATTENZIONE",
          text: `Devi selezionare almeno 2 bancali prima di unirli`,
          icon: "warning",
        });
      }
    },
    openDetails(codicePallet) {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("showContenutoBancale", [
        codicePallet,
        this.searchingLdV.text,
      ]);
    },
    modificaBancale(bancale) {
      this.store.dispatch("changeBodyClass", "formOpen");
      this.store.dispatch("showModificaBancale", bancale);
    },
    doShipBancale(codicePallet) {
      Swal.fire({
        title: "ATTENZIONE",
        text: `Sei sicuro di voler affidare il bancale ${codicePallet}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        confirmButtonText: "AFFIDA",
        cancelButtonText: "ANNULLA",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.shipBancale(codicePallet, true);
          this.searchBancale();
        }
      });
    },
    async doMultipleShipBancale() {
      if (this.selected.length != 0) {
        Swal.fire({
          title: "ATTENZIONE",
          text: `Sei sicuro di voler affidare i bancali?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "green",
          cancelButtonColor: "red",
          confirmButtonText: "AFFIDA",
          cancelButtonText: "ANNULLA",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const promises = this.selected.map((pallet) =>
                this.shipBancale(pallet.codicePallet, false)
              );
              await Promise.all(promises);
              Swal.fire({
                title: "AFFIDO RIUSCITO",
                text: `I bancali sono stati affidati con successo`,
                icon: "success",
              });
              this.searchBancale();
            } catch (error) {
              Swal.fire({
                title: "ERRORE",
                text: `C'è stato un errore nella spedizione di uno o più bancali`,
                icon: "error",
              });
            }
          }
        });
      } else {
        Swal.fire({
          title: "ATTENZIONE",
          text: `Devi selezionare dei bancali prima di affidarli`,
          icon: "warning",
        });
      }
    },

    async shipBancale(codicePallet, single) {
      const apiUrl = `${this.$apiBaseUrl}/pallet/shipPallet/${codicePallet}`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      try {
        const response = await axios.post(apiUrl, null, config);
        console.log("Response:", response.data);
        if (single) {
          Swal.fire({
            title: "AFFIDO RIUSCITO",
            text: `Il bancale è stato affidato con successo`,
            icon: "success",
          });
          this.searchBancale();
        }
      } catch (error) {
        console.error("Error:", error.message);
        throw error; // Propaga l'errore per essere gestito da Promise.all
      }
    },
    formattaDataOra(date) {
      return this.dateTime
        .fromISO(date + "Z")
        .setZone("Europe/Rome")
        .setLocale("it")
        .toFormat("dd/MM/yyyy | HH:mm:ss");
    },
    formatDate(inputDate) {
      if (inputDate === null) {
        return "nessuna data selezionata";
      } else {
        // Parse the input date string
        const date = new Date(inputDate);

        // Get day, month, and year components
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        // Construct the formatted date string
        const formattedDate = `${day} / ${month} / ${year}`;

        return formattedDate;
      }
    },
    async loadItems({ page, itemsPerPage, sortBy }) {
      this.panel = null;
      this.spedito =
        this.dataSpedizioneA || this.dataSpedizioneDa || this.spedito == true
          ? true
          : false;

      this.loading = true;
      let stato = null;
      console.log(this.stato + " " + stato);
      if (this.stato == "CHIUSO") {
        stato = false;
      } else if (this.stato == "APERTO") {
        stato = true;
      }

      console.log(this.stato + " " + stato);

      let filters = {
        stato: stato,
        spedito:
          this.dataSpedizioneA || this.dataSpedizioneDa || this.spedito == true
            ? true
            : false,
        codicePallet:
          this.searchingBancale.text == "" ? null : this.searchingBancale.text,
        ldv: this.searchingLdV.text == "" ? null : this.searchingLdV.text,
        corriere: this.corriere == null ? null : this.corriere.toLowerCase(),
        dataSpedizioneDa:
          this.dataSpedizioneDa == null
            ? null
            : DateTime.fromJSDate(this.dataSpedizioneDa)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        dataSpedizioneA:
          this.dataSpedizioneA == null
            ? null
            : DateTime.fromJSDate(this.dataSpedizioneA)
                .setZone("Europe/Rome")
                .toFormat("yyyy-MM-dd"),
        userMail:
          this.searchingUtente.text.trim().toLowerCase() == ""
            ? null
            : this.searchingUtente.text.trim().toLowerCase(),
      };

      const apiUrl = `${this.$apiBaseUrl}/pallet/findAllPalletByFiltro`;
      const bearerToken = this.$keycloak.token;
      console.log(bearerToken);

      const config = {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      };

      console.log(sortBy);

      let urlParams = {
        filtro: filters,
        sortBy: sortBy[0],
        page: page - 1,
        itemsPage: itemsPerPage,
      };

      console.log(urlParams);

      try {
        const response = await axios.post(apiUrl, urlParams, config);
        console.log("Response:", response.data);
        this.bancaliServer = response.data.palletViewList;
        this.totalItems = response.data.length;
        this.loading = false;
      } catch (error) {
        console.error("Error:", error.message);
        Swal.fire({
          title: "ERRORE",
          text: `C'è stato un errore nel caricamento dei bancali in uscita`,
          icon: "error",
        });
      }

      console.log(sortBy, page, itemsPerPage, filters);
    },
  },

  watch: {},
};
</script>

<style scoped>
.filters-actions-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
}

select.v-select-select {
  height: 48px !important;
}

.filters {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
  column-gap: 10px !important;
  width: fit-content !important;
}

.sub-filters {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
  flex-wrap: wrap !important;
  width: 690px !important;
  column-gap: 10px !important;
}

.v-btn--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-text-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.v-field--variant-outlined {
  border: thin solid #d0d0d0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.user-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-input {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
}

.v-card-title {
  font-size: 2rem;
}

.warning-text {
  font-size: 1.2rem;
  text-align: center;
  width: 400px;
}

.bancali-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-list-item--active {
  background-color: #2196f3;
  color: white;
}
</style>
