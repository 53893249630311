<template>
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 50px">
        <div style="width: 100%;">
            <div class="page-title-container">
                <p class="title-container">
                    GESTIONE COLLI BLOCCATI
                </p>
            </div>
            <div class="table-container">
                <ColliBloccatiTable />
            </div>
        </div>
        <div id="form-position" class="form-container" v-if="this.store.state.colliBloccati.showSbloccaColloConfirmState || this.store.state.colliBloccati.showBloccaColloFormState">
            <BloccaColloForm @blocca-collo="bloccaCollo" v-if="this.store.state.colliBloccati.showBloccaColloFormState"/>
            <SbloccaColloConfirm @sblocca-collo="sbloccaCollo" :collo="this.store.state.colliBloccati.editingColloBloccato" v-if="this.store.state.colliBloccati.showSbloccaColloConfirmState"/>
        </div>
    </div>
</template>

<script>
    import { useStore } from 'vuex';
    import ColliBloccatiTable from "../components/ColliBloccatiTable.vue";
    import BloccaColloForm from "../components/BloccaColloForm.vue";
    import SbloccaColloConfirm from "../components/SbloccaColloConfirm.vue";
    import Swal from "sweetalert2";
    const axios = require('axios');
    const { DateTime } = require("luxon");
    export default {
        name: "ColliBloccatiView",

        components: {
            ColliBloccatiTable,
            BloccaColloForm,
            SbloccaColloConfirm,
        },

        data() {
            return {}
        },

        setup() {
            const store = useStore();
            return {
                store,
                dateTime: DateTime,
            };
        },
        
        methods: {
            async bloccaCollo(codiceCollo) {
                console.log(codiceCollo);
                let collo = {
                    ldv: codiceCollo,
                    bloccato: true
                }
                const apiUrl = `${this.$apiBaseUrl}/colli/bloccati`;
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken);

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.post(apiUrl, collo, config);
                    console.log('Response:', response.data);
                    this.store.dispatch("changeBodyClass", "")
                    this.store.dispatch('closeBloccaColloForm')
                    Swal.fire({
                        title: "COLLO BLOCCATO",
                        text: `Il collo ${codiceCollo} è stato bloccato.`,
                        icon: "success",
                    });
                } catch (error) {
                    Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nel blocco del collo`,
                        icon: "error",
                    });
                }
                
            },
            async sbloccaCollo(id, codiceCollo) {
                console.log(codiceCollo);
                console.log(id);
                let date = new Date()
                let collo = {
                    ldv: codiceCollo,
                    stato: "RICEVUTO_MAGAZZINO",
                    createDate: date
                }
                const apiUrl = `${this.$apiBaseUrl}/colli`;
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken);

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.post(apiUrl, collo, config);
                    console.log('Response:', response.data);
                    this.store.dispatch("changeBodyClass", "")
                    this.store.dispatch('closeSbloccaColloConfirm')
                    Swal.fire({
                        title: "COLLO SBLOCCATO",
                        text: `Il collo ${codiceCollo} è stato sbloccato.`,
                        icon: "success",
                    });
                } catch (error) {
                    console.error('Error:', error.message);
                    Swal.fire({
                        title: "ERRORE",
                        text: `C'è stato un errore nello sblocco del collo`,
                        icon: "error",
                    });
                }
            },
        },


    }
</script>

<style scoped>

    .page-title-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title-container {
        font-size: 2rem;
        letter-spacing: 2px;
    }

    .table-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-container {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        background-color: rgba(0,0,0,0.25);
    }

    #form-position {
        position: absolute;
        z-index: 4 !important;
    }
    
    .v-btn--variant-outlined {
        border: thin solid #D0D0D0;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

</style>